import { getFormattedAmountUI } from '@axo/shared/util/formatters';

type TGetDisclaimerText = {
  labels?: {
    monthlyPaymentDisclaimer?: string;
  };
  loanDuration: number;
  totalUserPayment: number;
  totalLoanAmount: number;
  costForUser: number;
};

export const getDisclaimerText = ({
  labels,
  loanDuration,
  totalUserPayment,
  totalLoanAmount,
  costForUser,
}: TGetDisclaimerText) =>
  labels?.monthlyPaymentDisclaimer
    ? labels.monthlyPaymentDisclaimer
        ?.replace('{{loanAmount}}', getFormattedAmountUI(totalLoanAmount))
        .replace('{{loanDuration}}', loanDuration.toString())
        .replace('{{costForUser}}', getFormattedAmountUI(costForUser))
        .replace(
          '{{totalUserPayingAmount}}',
          getFormattedAmountUI(totalUserPayment)
        )
    : undefined;
