import { tracking } from '@axo/form/data-access/tracking';
import { cookieWithConverter, deleteCookie } from '@axo/shared/util/cookie';
import { captureMessage, captureException } from '@sentry/react';
import { LDFlagValue } from 'launchdarkly-react-client-sdk';
import { Question, SurveyModel } from 'survey-core';
import { changeHandler } from '../../backend-sync';
import reloadState from '../../backend-sync/reloadState';
import { getLibConfig } from '../../configure';
import { clearReloadData } from '../../hooks/useReloadFromBackendOnInit';
import { getFormState } from '../../store';
import { IChanges } from '../../types/data/IChanges';
import { ISurveyOnCompleting } from '../../types/survey/ISurveyOnCompleting';
import { getStepName } from '../../utils/getStepName';
import runWithManualRetry from '../../utils/runWithManualRetry';
import { runAsStatefulAsync } from '../../utils/statefulAsync';
import injectData from '../injectData';
import { getFirstQuestionWithError } from '../utils/getFirstQuestionWithError';
import { getTrackingData } from './getTrackingData';

const trackingCookieName = 'track_application_complete';
const attributionCookieName = 'axotc';

export const surveyCompleting = async (
  survey: SurveyModel,
  options: ISurveyOnCompleting,
  experimentValueGetter: () => Promise<LDFlagValue>,
  onComplete?: (data: Record<string, unknown>) => Promise<void>
) => {
  options.allowComplete = false;

  const nextQuestionWithError = getFirstQuestionWithError(survey);
  if (nextQuestionWithError) {
    survey.currentPage = nextQuestionWithError.page;
    return;
  }

  registerNullChangesToHiddenFields(survey);
  await changeHandler.onSubmit();

  await runAsStatefulAsync(async () => {
    if (await finalBackendSync(survey)) {
      const finalPageInSurvey = survey.pages[survey.pages.length - 1];

      const { loanApplication, customer } = getFormState();

      if (!loanApplication?.ID || !customer?.JWT)
        throw new Error('Missing data to complete application');

      await tracking.completeApplication({
        step: {
          name: getStepName(finalPageInSurvey),
        },
        loanApplicationId: loanApplication?.ID,
        token: customer.JWT,
        data: survey.data,
        baseUrl: getLibConfig().API_URL,
      });

      clearReloadData();

      const hostName = window.location.host;
      deleteCookie(attributionCookieName, hostName);

      if (!loanApplication?.ID || !customer?.JWT)
        throw new Error('Missing application ID or JWT in formstate');

      try {
        await onComplete?.(getTrackingData(survey));

        await redirect({
          locale: survey.locale,
          applicationID: loanApplication?.ID,
          token: customer?.JWT,
          experimentValueGetter,
        });
      } catch (error) {
        console.warn('Execution halted:', error);
      }
    }
  });
  storeTrackingDataInCookie(survey);
};

const redirect = async ({
  locale,
  applicationID,
  token,
  experimentValueGetter,
}: {
  locale: string;
  applicationID: string;
  token: string;
  experimentValueGetter: () => Promise<LDFlagValue>;
}) => {
  const experimentUrl = await getSuccessPageOrDefault(
    locale,
    experimentValueGetter
  );
  const redirectUrl = addContextDataToUrl({
    applicationID: applicationID,
    token: token,
    url: experimentUrl,
    source: 'application-form-flow',
  });

  setTimeout(() => {
    // allow the tracking to be sent before redirecting
    window.location.href = redirectUrl;
  }, 500);
};

// TODO: remove this when tracking is using push events instead of cookies
const storeTrackingDataInCookie = async (survey: SurveyModel) => {
  const trackingData = getTrackingData(survey);

  const data = JSON.stringify(trackingData);
  const domainName = window.location.host.match(/\w+\.\w+$/)?.[0];

  cookieWithConverter.set(trackingCookieName, JSON.stringify(data), {
    path: '/',
    domain: '.' + domainName,
  });
};

const registerNullChangesToHiddenFields = (survey: SurveyModel) => {
  const fieldsNotToNull = [
    'LoanApplication/Purpose',
    'LoanApplication/InitialPurpose',
    'LoanApplication/TotalRefinancingAmount',
    'LoanApplication/LivedInMarketCountrySince',
    'Ignore/DebtRegistry',
    'Debt/Personal/OutstandingAmount',
  ];

  const questions = survey.getAllQuestions();

  let notVisibleFields: IChanges = {};
  questions.forEach((q) => {
    if (fieldsNotToNull.includes(q.name)) return;

    if (questionShouldBeNulled(q, survey)) {
      notVisibleFields = { ...notVisibleFields, [q.name]: null };
    }
  });

  if (Object.keys(notVisibleFields).length) {
    changeHandler.registerChange(notVisibleFields);
  }
};

const questionShouldBeNulled = (question: Question, survey: SurveyModel) => {
  const questions = survey.getAllQuestions();
  const questionsByName = questions.filter((q) => q.name === question.name);

  // if one field has multiple question instances, we do not want to null this if only one of them is hidden.
  return questionsByName
    .map(
      (q) =>
        (!q.isVisible || !q.isParentVisible) &&
        (q.value !== undefined || q.defaultValue !== undefined)
    )
    .reduce((sum, next) => sum && next);
};

const finalBackendSync = async (survey: SurveyModel): Promise<boolean> => {
  let success = true;
  changeHandler.shouldRegisterChanges(false);
  await runWithManualRetry(async () => {
    if (!(await backendIsInvalid(survey))) return;
    captureMessage(
      'Backend validation failed (will try to fix this by syncing with the backend).'
    );

    // allow changehandler to register changes again to update the data that was missing on the server
    changeHandler.shouldRegisterChanges(true);

    changeHandler.registerChange(survey.data);
    await changeHandler.onSubmit();

    if (await backendIsInvalid(survey)) {
      success = false;
      throw new Error(
        'Failed to validate backend data based on form rules and was not able to fix it by syncing.'
      );
    }
    // dont allow more changes after we've resynced backend.
    changeHandler.shouldRegisterChanges(false);
  });

  return success;
};

const backendIsInvalid = async (survey: SurveyModel): Promise<boolean> => {
  const frontendData = survey.data;

  const { loanApplication, customer } = getFormState();
  if (!loanApplication || !customer)
    throw new Error(
      'Expected loan application and customer to be defined in form state'
    );

  const backendData = await reloadState({
    loanApplicationId: loanApplication.ID,
    JWT: customer.JWT,
  });
  survey.clear(true, false);
  injectData(survey, backendData);

  const questionWithError = getFirstQuestionWithError(survey);

  survey.data = frontendData;

  return !!questionWithError;
};

/**
 * Until we have a url on the same domain use the full urls and not just the route.
 * We should not redirect to production thankyoupage when not in production environment - it will clutter tracking.
 **/
const supportedLocales = ['no', 'fi']; // Define your supported locales
type Locale = (typeof supportedLocales)[number];

const getLocaleFromHostname = (hostname: string): Locale | null => {
  const domainExtension = hostname.split('.').pop();
  return typeof domainExtension === 'string' &&
    supportedLocales.includes(domainExtension as Locale)
    ? (domainExtension as Locale)
    : null;
};

const getDefaultSuccessPageByLocale = async (
  locale: Locale | null,
  experimentValueGetter: () => Promise<LDFlagValue>
) => {
  const isEnvProduction =
    getLibConfig().ENV === 'production' || getLibConfig().ENV === 'prod';
  const hostname = window.location.origin;

  const flag_transition_thank_you_page = (await experimentValueGetter())
    ? 'true'
    : 'false';

  // (fallback) Infer locale from hostname if not provided, it is pulled from surveyjs
  // some recordings show users being redirected to frontpage, most probably it returned '' as value because there is no locale
  locale ??= getLocaleFromHostname(hostname);

  if (!locale || !supportedLocales.includes(locale)) {
    const errorMessage = `Invalid locale: ${locale}`;
    const error = new Error(errorMessage);
    captureException(error, {
      extra: {
        locale,
        hostname,
        isEnvProduction,
        flag_transition_thank_you_page,
      },
    });
    throw error;
  }

  //
  const destinations: Record<Locale, Record<'true' | 'false', string>> = {
    no: {
      true: isEnvProduction
        ? 'https://axofinans.no/soknad/forbrukslan/vennligst-vent'
        : `${hostname}/soknad/forbrukslan/vennligst-vent`,
      false: isEnvProduction
        ? 'https://forsikring.axofinans.no?form=new'
        : 'https://insurance.no.axo-test.io?form=new',
    },
    fi: {
      true: isEnvProduction
        ? 'https://axolaina.fi/hakemus/odota-hetki'
        : `${hostname}/hakemus/odota-hetki`,
      false: isEnvProduction
        ? 'https://vakuutus.axolaina.fi?form=new'
        : 'https://insurance.fi.axo-test.io?form=new',
    },
  } as const;

  const destinationUrl =
    destinations[locale]?.[flag_transition_thank_you_page] || '';

  // Validate the destination URL
  if (!destinationUrl) {
    const errorMessage = `Destination URL not found for locale: ${locale} with flag_transition_thank_you_page: ${flag_transition_thank_you_page}`;
    const error = new Error(errorMessage);
    captureException(error, {
      extra: {
        locale,
        isEnvProduction,
        hostname,
        flag_transition_thank_you_page,
      },
    });
    throw error;
  }

  if (flag_transition_thank_you_page !== 'true') {
    const errorMessage = `Form v1 feature flag fail: flag_transition_thank_you_page: ${flag_transition_thank_you_page}`;
    const error = new Error(errorMessage);
    captureException(error, {
      extra: {
        locale,
        isEnvProduction,
        hostname,
        flag_transition_thank_you_page,
        destinationUrl,
      },
    });
  }

  return destinationUrl;
};

const addContextDataToUrl = ({
  url,
  applicationID,
  token,
  source,
}: {
  url: string;
  applicationID: string;
  token: string;
  source: 'credit-card-flow' | 'application-form-flow';
}): string => {
  const u = new URL(url);
  u.searchParams.append('id', applicationID);
  u.searchParams.append('source', source);
  u.hash = `token=${token}`;
  return u.toString();
};

export const getSuccessPageOrDefault = (
  locale: string,
  experimentValueGetter: () => Promise<LDFlagValue>
) => {
  const customSuccessPage = document
    .querySelector('#axo-root')
    ?.getAttribute('data-success-page');

  const path =
    customSuccessPage ||
    getDefaultSuccessPageByLocale(locale, experimentValueGetter);

  if (customSuccessPage) {
    captureMessage(`Custom success page configured: ${customSuccessPage}`);
  }

  return path;
};
