import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import {
  setStoredCashAmount,
  setStoredRefinanceableDebts,
} from './setStoredValues';

type TExtendedLoanOverviewState = {
  _refinanceableDebts: TRefinanceableDebt[];
  _cashAmount: number;
  _loanDuration: number;
};

export enum TExtendedLoanOverviewActions {
  setIsCreditCardDebtChecked = 'setIsCreditCardDebtChecked',
  setIsUnsecuredDebtChecked = 'setIsUnsecuredDebtChecked',
  setCashAmount = 'setCashAmount',
  setLoanDuration = 'setLoanDuration',
  setDebtValues = 'setDebtValues',
  setDebtValuesAndCashAmount = 'setDebtValuesAndCashAmount',
  setRefinanceableDebts = 'setRefinanceableDebts',
}

type TExtendedLoanOverviewAction =
  | {
      type:
        | TExtendedLoanOverviewActions.setCashAmount
        | TExtendedLoanOverviewActions.setLoanDuration;
      payload: { value: number; loanApplicationID?: string };
    }
  | {
      type: TExtendedLoanOverviewActions.setDebtValues;
      payload: {
        creditCardDebt: number;
        unsecuredDebt: number;
      };
    }
  | {
      type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount;
      payload: {
        refinanceableDebts: TRefinanceableDebt[];
        cashAmount: number;
        loanApplicationID?: string;
      };
    }
  | {
      type: TExtendedLoanOverviewActions.setIsCreditCardDebtChecked;
      payload: boolean;
    }
  | {
      type: TExtendedLoanOverviewActions.setIsUnsecuredDebtChecked;
      payload: boolean;
    }
  | {
      type: TExtendedLoanOverviewActions.setRefinanceableDebts;
      payload: {
        refinanceableDebts: TRefinanceableDebt[];
        loanApplicationID?: string;
      };
    };

export const extendedLoanOverviewContextReducer = (
  state: TExtendedLoanOverviewState,
  action: TExtendedLoanOverviewAction
) => {
  const { type, payload } = action;

  switch (type) {
    case TExtendedLoanOverviewActions.setRefinanceableDebts:
      setStoredRefinanceableDebts({
        value: JSON.stringify(payload.refinanceableDebts),
        loanApplicationID: payload.loanApplicationID,
      });
      return {
        ...state,
        _refinanceableDebts: payload.refinanceableDebts,
      };
    case TExtendedLoanOverviewActions.setCashAmount:
      setStoredCashAmount({
        value: Math.max(0, payload.value),
        loanApplicationID: payload.loanApplicationID,
      });
      return {
        ...state,
        _cashAmount: Math.max(0, payload.value),
      };
    case TExtendedLoanOverviewActions.setLoanDuration:
      return {
        ...state,
        _loanDuration: payload.value,
      };
    case TExtendedLoanOverviewActions.setDebtValuesAndCashAmount:
      setStoredCashAmount({ value: Math.max(0, payload.cashAmount) });
      setStoredRefinanceableDebts({
        value: JSON.stringify(payload.refinanceableDebts),
        loanApplicationID: payload.loanApplicationID,
      });
      return {
        ...state,
        _refinanceableDebts: payload.refinanceableDebts,
        _cashAmount: Math.max(0, payload.cashAmount),
      };

    default:
      return state;
  }
};
