import { MessageBox } from '@axo/deprecated/util/ui-components';
import {
  DebtRegistry,
  DebtRegistryOld,
  IDebtRegistry,
} from '@axo/form/feature/debt-registry';
import { TProviders } from '@axo/shared/auth/providers';
import { AnalyticsEvent, useAnalytics } from '@axo/shared/services/analytics';
import { useFlags } from '@axo/shared/services/feature-flags';
import { MarketCountryCode } from '@axo/shared/types';
import { Spinner } from '@axo/ui-core/components/Spinner';
import { ReactElement, useContext, useEffect, useMemo, useRef } from 'react';
import { SurveyStateContext } from '../../../contexts/SurveyStateContext';
import DebtRegistryUnavailable from './DebtRegistryUnavailable';

const flags = {
  noDebtRegistryEnabled: 'noDebtRegistryEnabled',
  'loan-bankid-and-vipps': 'loan-bankid-and-vipps',
  bankIDProvider: 'bankIDProvider',
};

const manualInputEvent: AnalyticsEvent = {
  event: 'Debt Register Fill Manual Toggled',
  params: { value: true },
};

type TDebtRegistryNO = IDebtRegistry & {
  errors: { text: string }[];
  shouldIgnoreErrorMessage?: boolean;
  serviceUnavailableMessage?: string;
};

export function DebtRegistryNO(props: TDebtRegistryNO): ReactElement {
  const { errors, onToggleManualMode, serviceUnavailableMessage } = props;

  const {
    noDebtRegistryEnabled: isDebtRegistryAvailable = true,
    'loan-bankid-and-vipps': loanBankIdAndVipps,
    bankIDProvider,
  } = useFlags(flags);

  const isLoading = loanBankIdAndVipps === undefined;

  const { track } = useAnalytics();
  const isTrackEventSent = useRef(false);

  const loginButtonRef = useRef<HTMLButtonElement>(null);

  const {
    continueButtonContext: { clickedTimes },
  } = useContext(SurveyStateContext);

  const errorMessage =
    'Du må logge inn med BankId og dele din gjeldsregister-informasjon for å fortsette.';

  const hasErrors = errors.length > 0;

  // TODO temporary for experiment, needs refactoring, use Button component interface + context in MessageBoxBankID
  if (hasErrors) {
    //get user attention to login button by adding 'wobble' animation to it
    if (loginButtonRef?.current) {
      loginButtonRef.current.dataset.animation = 'wobble';
      loginButtonRef.current.onanimationend = () => {
        loginButtonRef?.current?.toggleAttribute('data-animation');
      };
    }
  }

  const allowManualInput = clickedTimes > 5;

  useEffect(() => {
    if (!isDebtRegistryAvailable) return;

    if (allowManualInput) {
      onToggleManualMode?.(true);

      if (!isTrackEventSent.current) {
        isTrackEventSent.current = true;
        track(manualInputEvent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowManualInput, isDebtRegistryAvailable]);

  useEffect(() => {
    if (!isDebtRegistryAvailable) onToggleManualMode?.(true);
  }, [isDebtRegistryAvailable, onToggleManualMode]);

  const debtRegistryProviders = useMemo(() => {
    if (bankIDProvider === TProviders.Criipto) {
      return {
        [TProviders.Criipto]: {
          country: MarketCountryCode.Norway,
          label: 'Logg inn med BankID',
        },
        [TProviders.Vipps]: {},
      };
    }

    return {
      [TProviders.BankID]: {
        serviceSourceUrl: props.assentlySrc,
      },
      [TProviders.Vipps]: {},
    };
  }, [bankIDProvider, props.assentlySrc]);

  if (!isDebtRegistryAvailable)
    return <DebtRegistryUnavailable message={serviceUnavailableMessage} />;

  if (isLoading) {
    return <Spinner />;
  }

  if (loanBankIdAndVipps) {
    return (
      <>
        <DebtRegistry
          applicationID={props.applicationID}
          axoApiUrl={props.endpoint}
          customerJWT={props.customerJWT}
          providers={debtRegistryProviders}
          onFetchDebt={props.onFetchDebt}
          allowManualInput={false}
          isSellingPointsVisible={false}
        />

        {hasErrors && !props.shouldIgnoreErrorMessage && (
          <MessageBox
            iconVisible={true}
            marginTop={true}
            variety="error"
            text={errorMessage}
          />
        )}
      </>
    );
  }

  return (
    <>
      <DebtRegistryOld
        forwardRef={loginButtonRef}
        allowManualInput={false}
        {...props}
      />

      {hasErrors && !props.shouldIgnoreErrorMessage && (
        <MessageBox
          iconVisible={true}
          marginTop={true}
          variety="error"
          text={errorMessage}
        />
      )}
    </>
  );
}
