'use client';

import { ErrorAlert } from '@axo/deprecated/util/ui-components';
import { FormFlagSet } from '@axo/form/configs';
import { tracking } from '@axo/form/data-access/tracking';
import { AuthenticationContextProvider } from '@axo/form/feature/axo-form-v2/lib/context/Authentication/AuthenticationContext';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ThemeProvider } from '@axo/ui-core/themes';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { Model, Serializer, SurveyError, SurveyModel } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Market, Variant, getConfig } from './config/getConfig';
import { useContinueFromFirstError } from './hooks/useContinueFromFirstError';
import { useCustomerAnalytics } from './hooks/useCustomerAnalytics';
import useFormState from './hooks/useFormState';
import { useNavigateByQueryParams } from './hooks/useNavigateByQueryParams';
import { useReloadFromBackendOnInit } from './hooks/useReloadFromBackendOnInit';
import { useTracking } from './hooks/useTracking';
import styles from './index.module.scss';
import localization from './localization';
import { resetStore } from './store';
import {
  surveyCompleting,
  surveyPageChanging,
  surveyValueChanged,
} from './survey';
import { addPageStructure } from './survey/add-page-structure';
import { SurveyStateContext } from './survey/contexts/SurveyStateContext';
import { surveyAfterRenderPage } from './survey/event-handlers/surveyAfterRenderPage';
import surveyPageChanged from './survey/event-handlers/surveyPageChanged';
import { register } from './survey/questions';
import { IPageStructure } from './types/survey/IPageStructure';
import { ISurveyConfig } from './types/survey/ISurveyConfig';
import { ISurveyOnAfterRenderPage } from './types/survey/ISurveyOnAfterRenderPage';
import { ISurveyOnCompleting } from './types/survey/ISurveyOnCompleting';
import { ISurveyOnCurrentPageChangingOptions } from './types/survey/ISurveyOnCurrentPageChanging';
import { ISurveyOnPageChanged } from './types/survey/ISurveyOnPageChanged';
import { ISurveyOnValueChangedOptions } from './types/survey/ISurveyOnValueChangedOptions';
import {
  IConfigurableProperties,
  setDefaultValuesFromDataAttributes,
} from './utils/SetDefaultValuesFromDataAttributes';
import { eventConsumer, formEventProducer } from './utils/events';
import { setFormValuesFromQueryParams } from './utils/setFormValuesFromQueryParams';
import softVerifyFieldNames from './utils/verifyFieldNames';

register?.();

const myCss = {
  page: {
    root: 'form_page_root',
  },
  question: {
    indent: 0,
    mainRoot: 'question_root',
  },
  panel: {
    title: 'sv_p_title',
    titleExpandable: 'sv_p_title_expandable',
    titleExpanded: 'sv_p_title_expanded',
    titleCollapsed: 'sv_p_title_collapsed',
    titleOnError: '',
    icon: 'sv_panel_icon',
    iconExpanded: 'sv_expanded',
    description: 'small sv_p_description',
    content: 'content-axoform',
    container: 'container-axoform',
    footer: 'sv_p_footer',
    number: 'sv_q_num',
    requiredText: 'sv_q_required_text',
  },
};

interface IForm {
  market: Market;
  variant: Variant;
  defaultValues?: IConfigurableProperties;
  onComplete?: (args?: { searchParams?: string }) => void | Promise<void>;
}

export const AxoFormV1 = ({
  market,
  variant,
  defaultValues,
  onComplete,
}: IForm) => {
  let { savingsVolume } = useFlags(FormFlagSet);
  useFlagImpression(FormFlagSet, { savingsVolume });
  savingsVolume ??= { position: 'none' };

  const searchParams =
    typeof window !== 'undefined'
      ? new URLSearchParams(window.document.location.search)
      : new URLSearchParams();

  const [formState] = useFormState();
  const { setupState } = formState;

  const config = getConfig({ market, variant });

  const formVariant = config.variant;
  const locale = searchParams.get('locale') || config.locale || '';

  const [survey] = useState<Model>(() => {
    tracking.setTag('axoform.config.market', config.market);
    tracking.setTag('axoform.config.form-variant', config.variant);

    const dataWithStructure = addPageStructure(
      config.json.surveyConfig as unknown as ISurveyConfig,
      config.json.surveyPageStructure as unknown as IPageStructure[]
    );

    const survey = new Model(dataWithStructure);
    softVerifyFieldNames(survey);
    survey.locale = locale;

    if (defaultValues?.appliedAmount) {
      survey?.setValue(
        'LoanApplication/AppliedAmount',
        defaultValues?.appliedAmount
      );
    }

    if (defaultValues?.refinancing) {
      survey?.setValue('LoanApplication/Purpose', 'Refinancing loan');
      // default value from cms (if refinancing is toggled on)
      survey?.setValue('Ignore/ConsolidateDebt', 'true');
    }

    return survey;
  });

  useEffect(() => {
    const { position } = savingsVolume;

    survey?.setValue('Ignore/AmountSaved', 'hide');
    survey?.setValue('Ignore/SavingsExample', position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savingsVolume?.position]);

  useEffect(() => {
    const unsubscribe = eventConsumer.abtest.ready((e) => {
      if (e.detail.formValues.loanAmount) {
        survey?.setValue(
          'LoanApplication/AppliedAmount',
          e.detail.formValues.loanAmount
        );
      }
      // TODO: AB test to be able to hide/show the slider instead of dropdown in NO for appliedAmount. Should be removed after the test (edit: 22.09.2023)
      if (e.detail.formValues.useSliderAppliedAmount) {
        survey?.setValue('Ignore/ABTest/Slider', 'true');
      }
    });

    return () => unsubscribe();
  }, [survey]);

  useEffect(() => {
    if (formState.setupState === 'ready')
      setTimeout(() => formEventProducer.emit.ready(), 1);
  }, [formState.setupState]);

  // Since we don't have control over surveyJS internal html, we set this ID by javascript. ID is used by VWO to add tracking on the form.
  useEffect(() => {
    if (setupState === 'idle') {
      setFormValuesFromQueryParams(survey);
    }

    if (setupState === 'ready') {
      const form = document.getElementsByTagName('form')[0];
      form.id = 'axo-form-root';
      form.noValidate = true;

      //might be legacy, not sure if any of the white labels are using it 13.09.2023
      const defaultValuesString = document
        .querySelector('#axo-root')
        ?.getAttribute('data-default-values');

      if (defaultValuesString) {
        if (!defaultValuesString.includes('undefined')) {
          const parsedData = JSON.parse(defaultValuesString);
          if (parsedData && survey) {
            setDefaultValuesFromDataAttributes(parsedData, survey);
          }
        }
      }
    }
  }, [setupState, survey]);

  const translations = useMemo(() => localization(locale), [locale]);

  Serializer.findProperty('question', 'minWidth').defaultValue = 'auto';
  useReloadFromBackendOnInit(survey);
  useContinueFromFirstError(survey, { enabled: formVariant !== 'pre' });
  useNavigateByQueryParams(survey);

  useEffect(() => {
    return () => resetStore();
  }, []);

  useCustomerAnalytics();

  const [clickedTimes, setClickedTimes] = useState(0);

  return (
    <div
      className={clsx(styles.AxoFormV1, 'AxoFormV1')}
      style={
        formVariant !== 'pre' && formState.setupState !== 'ready'
          ? { opacity: 0 }
          : { opacity: 1, transitionDuration: '0.3s' }
      }
    >
      <SurveyStateContext.Provider
        value={{
          hasPendingRequest: formState.loadingState === 'pending',
          continueButtonContext: {
            clickedTimes,
            increaseContinueClickCounter: () =>
              setClickedTimes((state) => ++state),
          },
        }}
      >
        <FormV1
          formVariant={formVariant}
          survey={survey}
          resetCounter={() => setClickedTimes(0)}
          market={market}
          onComplete={onComplete}
        />
        {formState.errors.length > 0 && (
          <ErrorAlert
            title={translations.genericErrorAlert.title}
            paragraphs={translations.genericErrorAlert.paragraphs}
            buttonText={translations.genericErrorAlert.retry}
            onButtonClick={async () => {
              for (const error of formState.errors) {
                await error.retry();
              }
            }}
          />
        )}
      </SurveyStateContext.Provider>
    </div>
  );
};

type FormV1Props = {
  survey: Model | undefined;
  formVariant: Variant;
  resetCounter: () => void;
  market: Market;
  onComplete?: (args?: { searchParams?: string }) => void | Promise<void>;
};

function FormV1(props: FormV1Props) {
  const { survey, formVariant, resetCounter, onComplete } = props;

  const [changeCounter, setChangeCounter] = useState(0);

  const {
    onStepViewed,
    onStepInitiated,
    onStepComplete,
    onSurveyCompleted,
    onDebtBalanceCompleted,
  } = useTracking();

  const [flagImpression, setFlagImpression] = useState({});
  let { transition_thank_you_page } = useFlags(FormFlagSet);
  useFlagImpression(FormFlagSet, flagImpression);
  transition_thank_you_page ??= true;

  async function useExperimentValue() {
    setFlagImpression({ transition_thank_you_page });
    return transition_thank_you_page;
  }

  const _onStepComplete = async (
    step: number,
    data: Record<string, unknown>,
    debtBalanceData?: Record<string, number>
  ) => {
    if (props.market === 'fi' && step === 1) {
      await onStepInitiated(1, {
        loanAmount: data.loanAmount,
        loanDuration: data.loanDuration,
        hasRecommendedDurationRange: (data.loanDuration as number) >= 12,
      });
    }

    if (debtBalanceData) {
      const { consumerLoan, creditCard } = debtBalanceData;

      await onDebtBalanceCompleted({
        loanDebtBalance: (consumerLoan as number) || 0,
        creditCardDebtBalance: (creditCard as number) || 0,
      });
    }

    onStepComplete(step, data);
  };

  const onCurrentPageChanging = async (
    survey: SurveyModel,
    options: ISurveyOnCurrentPageChangingOptions
  ) => {
    const shouldTrackDebtBalance =
      formVariant === 'main' &&
      props.market === 'fi' &&
      options.oldCurrentPage.num === 5;

    await surveyPageChanging({
      survey,
      options,
      variant: formVariant,
      onComplete: _onStepComplete,
      shouldTrackDebtBalance,
    });
  };

  const onCurrentPageChanged = async (
    survey: SurveyModel,
    options: ISurveyOnPageChanged
  ) => {
    // This needs to happen ASAP as survey renders the next page before the promise is settled.
    resetCounter();

    await surveyPageChanged(survey, options, formVariant);
  };

  const onAfterRenderPage = async (
    survey: SurveyModel,
    options: ISurveyOnAfterRenderPage
  ) => {
    await surveyAfterRenderPage(survey, options, formVariant, onStepViewed);
  };

  const onCompleting = async (
    survey: SurveyModel,
    options: ISurveyOnCompleting
  ) => {
    if (formVariant === 'pre') {
      options.allowComplete = false;
      console.error('Submit failed: submitting is disabled on the pre form');
      return;
    }

    const onSurveyCompletedBeforeRedirect = async (
      data: Record<string, unknown>
    ) => {
      await onSurveyCompleted(data);
      await onComplete?.();
    };

    await surveyCompleting(
      survey,
      options,
      useExperimentValue,
      onSurveyCompletedBeforeRedirect
    );
  };

  const onSettingQuestionErrors = async (
    survey: SurveyModel,
    options: { errors: SurveyError[] }
  ) => {
    if (options?.errors) {
      const errors = options.errors;
      for (let i = 0; i < errors.length; i += 1) {
        errors[i].visible = false;
      }
    }
  };

  const onValueChanged = async (
    survey: SurveyModel,
    options: ISurveyOnValueChangedOptions
  ) => {
    setChangeCounter((prev) => ++prev);
    surveyValueChanged(survey, options, onStepInitiated);
  };

  return (
    <AuthenticationContextProvider>
      <div style={{ display: 'none' }}>
        {/* Sometimes SurveyJS won't rerender the page even though data has changed
  (e.g. when updating dynamic titles/labels). This hack ensures that the form
  is rerendered each time any value changes, so that dynamic ui that depends
  on the form values is always updated. */}
        {changeCounter}
      </div>
      <ThemeProvider theme="axo-finans">
        <Survey
          model={survey}
          css={myCss}
          onCurrentPageChanging={onCurrentPageChanging}
          onCurrentPageChanged={onCurrentPageChanged}
          onAfterRenderPage={onAfterRenderPage}
          onCompleting={onCompleting}
          onSettingQuestionErrors={onSettingQuestionErrors}
          onValueChanged={onValueChanged}
        />
      </ThemeProvider>
    </AuthenticationContextProvider>
  );
}
