export const FormFlagSet = {
  'transition-thank-you-page': 'transition-thank-you-page',
  savingsVolume: 'savingsVolume',
  'enable-chat-in-sites': 'enable-chat-in-sites',
  transition_thank_you_page: 'transition_thank_you_page',
  'storebrand-enabled': 'storebrand-enabled',
  savingsExample: 'savingsExample',
  POPInsuranceEnabled: 'POPInsuranceEnabled',
  'enable-new-loan-duration-selector': 'enable-new-loan-duration-selector',
  'enable-card-story-card': 'enable-card-story-card',
  'enable-cookie-consent-background': 'enable-cookie-consent-background',
  useRefactoredDebtRegistry: 'useRefactoredDebtRegistry',
  'redirect-to-mypage-with-authentication':
    'redirect-to-mypage-with-authentication',
  bankIDProvider: 'bankIDProvider',
} as const;

export const PermanentFormFlagSet = {
  // Data Sharing Status Permanent Flags

  // DK https://app.launchdarkly.com/team-1/test/features/eskat-status/variations
  'eskat-status': 'eskat-status',

  // DK https://app.launchdarkly.com/team-1/test/features/mitid-status/variations
  'mitid-status': 'mitid-status',

  // NO https://app.launchdarkly.com/projects/team-1/flags/debt-registry-status-norway/variations
  'debt-registry-status-norway': 'debt-registry-status-norway',

  // ALL https://app.launchdarkly.com/projects/team-1/flags/skip-insurance-switch
  'skip-insurance-switch': 'skip-insurance-switch',
};
