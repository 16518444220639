import { loan_application } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export const getAllLeads =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (loanApplicationId: string): Promise<loan_application.Lead[]> => {
    const url = new URL(`/lead/${loanApplicationId}`, baseUrl);

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error fetching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const postLead =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (
    loanApplicationId: string,
    partner: loan_application.Partner
  ): Promise<loan_application.Lead> => {
    const url = new URL(`/lead/${loanApplicationId}`, baseUrl);

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ Partner: partner }),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error posting ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const deleteLead =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (
    loanApplicationID: string,
    partner: loan_application.Partner
  ): Promise<void> => {
    const url = new URL(`/lead/${loanApplicationID}/${partner}`, baseUrl);

    const response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.ok) return;

    throw new Error(
      `Error deleting ${response.url}: ${response.status} ${response.statusText}`
    );
  };
