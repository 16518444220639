import { SaveAmount } from '@axo/deprecated/util/ui-components';
import { IDebtsResponse } from '@axo/form/feature/debt-registry';
import { ComponentProps, createElement } from 'react';
import { ElementFactory, QuestionHtmlModel, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import { getStoredRefinanceableDebts } from '../../../../components/ExtendedLoanOverview/context/ExtendedLoanOverviewContext/store/getStoredValues';
import { ExtendedLoanOverview } from '../../../../components/ExtendedLoanOverview/ExtendedLoanOverview';
import { getRefinanceableDebts } from './ExtendedLoanOverview/utils/getRefinanceableDebts';

const labels = {
  title: {
    noDebt: 'Du har ingen gjeld å refinansiere!',
    refinanceableDebts: 'Spar penger hver måned ved å refinansiere!',
  },
  description: {
    noDebt:
      'Gjeld som kan refinansieres inkluderer gjeld opptil 800 000 kr med rente over 8% og all kredittkortbruk over 25 000 kr. Vi fant ingen gjeld å refinansiere, og du er straks ferdig med lånesøknaden. Bruk skyveknappen under for å bekrefte ønsket lånebeløp.',
    refinanceableDebts:
      'Vi har identifisert gjeld som du kan samle i ett lån med lavere rente. Dette vil redusere dine månedlige kostnader og gi deg bedre kontroll over økonomien. De forhåndsvalgte boksene viser hvilke lån du kan refinansiere. Bruk skyveknappen lenger ned for å velge hvor mye ekstra du ønsker å låne.',
  },
};

class QuestionModel extends QuestionHtmlModel {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  getType(): string {
    return this.questionType ?? '';
  }
}

export class SurveyQuestion extends SurveyQuestionElementBase {
  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  protected renderElement(): JSX.Element | null {
    const debts =
      (
        this.question.survey.getQuestionByName('Ignore/DebtRegistry')?.value
          ?.data as IDebtsResponse
      )?.debts ??
      getStoredRefinanceableDebts({}) ??
      undefined;

    const refinanceableDebts: ComponentProps<
      typeof ExtendedLoanOverview
    >['refinanceableDebts'] = getRefinanceableDebts({
      refinanceableDebts: this.question.refinanceableDebts,
      isDebtRegistry: true,
      survey: this.question.survey,
      debtRegistryDebts: debts,
      isEmptyDebtsVisible: this.question.isEmptyDebtsVisible,
    });

    if (!refinanceableDebts.length)
      return (
        <SaveAmount
          title={labels.title.noDebt}
          description={labels.description.noDebt}
          icon="info"
        />
      );

    return (
      <SaveAmount
        title={labels.title.refinanceableDebts}
        description={labels.description.refinanceableDebts}
        icon="savings"
      />
    );
  }
}

export function registerSaveAmountQuestion() {
  const questionType = 'Axo Save Amount NO';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [],
    () => new QuestionModel(questionType, ''),
    'question'
  );

  ReactQuestionFactory.Instance.registerQuestion(
    questionType,
    (props: unknown) => createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(questionType, (name: string) => {
    return new QuestionModel(questionType, name);
  });
}
