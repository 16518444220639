import { TRefinanceableDebt } from '../../../../../../components/ExtendedLoanOverview/context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext.types';

const creditCardDebtPriority: string[] = [
  'creditCardDebt',
  'unsecuredDebt',
  'cash',
];

const unsecuredDebtPriority: string[] = [
  'unsecuredDebt',
  'creditCardDebt',
  'cash',
];

type TGetAllocationPriority = {
  isDebtRegistry?: boolean;
  creditCardDebt?: number;
  unsecuredDebt?: number;
  refinanceableDebts?: TRefinanceableDebt[];
};

export const getAllocationPriority = ({
  isDebtRegistry = false,
  creditCardDebt = 0,
  unsecuredDebt = 0,
  refinanceableDebts,
}: TGetAllocationPriority) => {
  if (isDebtRegistry) {
    if (!refinanceableDebts) return ['cash'];

    return [...refinanceableDebts.map((debt) => debt.key), 'cash'];
  }

  return creditCardDebt > unsecuredDebt
    ? creditCardDebtPriority
    : unsecuredDebtPriority;
};
