import { tracking } from '@axo/form/data-access/tracking';
import { SurveyModel } from 'survey-core';

import { Variant } from '../../config/getConfig';
import { getLibConfig } from '../../configure';
import { getFormState } from '../../store';
import { ISurveyOnPageChanged } from '../../types/survey/ISurveyOnPageChanged';
import { getStepName } from '../../utils/getStepName';

const surveyPageChanged = async (
  survey: SurveyModel,
  options: ISurveyOnPageChanged,
  variant: Variant
) => {
  if (
    options.isNextPage &&
    options.oldCurrentPage.num === 1 &&
    variant === 'pre'
  ) {
    // Emit an event when step1 is filled out. This is used to trigger a redirect in the parent website. This would only happen on the pre variant of the form.
    const event = new Event('AxoFormStep1Complete');
    document.dispatchEvent(event);
  }

  const { loanApplication, customer, setupState } = getFormState();
  if (options.isNextPage && setupState === 'ready') {
    if (!loanApplication?.ID || !customer?.JWT)
      throw new Error('Missing loan application id or customer jwt');

    await tracking.completeStep({
      step: {
        name: getStepName(options.oldCurrentPage),
      },
      loanApplicationId: loanApplication?.ID,
      token: customer.JWT,
      data: survey.data,
      baseUrl: getLibConfig().API_URL,
    });
  }
};

export default surveyPageChanged;
