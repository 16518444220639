import { TStoredValues } from './storedValues.types';

export const clearStoredCashAmount = () => {
  sessionStorage.removeItem(TStoredValues.cashAmount);
};

export const clearStoredAppliedAmount = () => {
  sessionStorage.removeItem(TStoredValues.appliedAmount);
};

export const clearStoredRefinanceableDebts = () => {
  sessionStorage.removeItem(TStoredValues.refinanceableDebts);
};

export const clearAllStoredValues = () => {
  clearStoredCashAmount();
  clearStoredRefinanceableDebts();
  clearStoredAppliedAmount();
};
