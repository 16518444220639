import { debounce } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { calculateMonthlyPayment } from '../../../../survey/questions/utils/CalculateMonthlyPayment';
import { TBlockType } from '../../ExtendedLoanOverview.types';
import { getLoanAllocation } from '../../utils/getLoanAllocation';
import { getRefinanceAmount } from '../../utils/getRefinanceAmount';
import getWarningBoxVisibility from '../../utils/getWarningBoxVisibility';
import {
  TExtendedLoanOverviewContext,
  TExtendedLoanOverviewContextProviderProps,
} from './ExtendedLoanOverviewContext.types';
import {
  getStoredAppliedAmount,
  getStoredCashAmount,
  getStoredRefinanceableDebts,
} from './store/getStoredValues';
import {
  TExtendedLoanOverviewActions,
  extendedLoanOverviewContextReducer,
} from './store/reducer';
import { setStoredAppliedAmount } from './store/setStoredValues';
import { getDebtValuesOnPropChange } from './utils/getDebtValuesOnPropChange';
import { getInitialValues } from './utils/getInitialValues';
import { getValuesForRefinanceValueChange } from './utils/getValuesForRefinanceValueChange';

const ExtendedLoanOverviewContext = createContext<
  TExtendedLoanOverviewContext | undefined
>(undefined);

export const ExtendedLoanOverviewContextProvider = ({
  children,
  refinanceableDebts,
  loanDuration,
  maxLoanAmount,
  allocationPriority,
  refinanceableDebtsExtraProps,
  currency = '€',
  appliedAmount,
  isUpSell = false,
  loanApplicationID,
  initialEditField,
  onRefinanceAmountChange,
  onLoanDurationChange,
  onTotalAmountChange,
}: TExtendedLoanOverviewContextProviderProps) => {
  const [initialAppliedAmount, setInitialAppliedAmount] = useState(
    getStoredAppliedAmount({ loanApplicationID }) || appliedAmount
  );

  const maxRefinanceableDebtsValuesForSyncPurposes = useRef<number[]>(
    refinanceableDebts.map((debt) => debt.value)
  );

  const [editMode, setEditMode] = useState<TBlockType | undefined>(
    initialEditField
  );

  const storedRefinanceableDebts = getStoredRefinanceableDebts({
    refinanceableDebts,
  });

  const [{ _refinanceableDebts, _cashAmount, _loanDuration }, dispatch] =
    useReducer(extendedLoanOverviewContextReducer, {
      _refinanceableDebts:
        storedRefinanceableDebts ??
        refinanceableDebts.map((debt) => ({ ...debt, isChecked: true })) ??
        undefined,
      _cashAmount:
        getStoredCashAmount({ loanApplicationID }) ?? appliedAmount ?? 0,
      _loanDuration: loanDuration,
    });

  const [isInitialAllocationComplete, setIsInitialAllocationComplete] =
    useState(false);

  const _refinanceAmount = useMemo(
    () =>
      getRefinanceAmount({
        refinanceableDebts: _refinanceableDebts,
      }),
    [_refinanceableDebts]
  );

  const isRefinanceableDebtsToggleOnly = useMemo(
    () => refinanceableDebtsExtraProps?.editType === 'toggle',
    [refinanceableDebtsExtraProps]
  );

  useEffect(() => {
    const {
      cashAmount: newCashValue,
      refinanceableDebts: newRefinanceableDebts,
    } = getInitialValues({
      isUpSell,
      refinanceableDebts,
      appliedAmount,
      loanApplicationID,
    });

    const storedAppliedAmount = getStoredAppliedAmount({
      loanApplicationID,
    });

    if (!storedAppliedAmount) {
      setStoredAppliedAmount({ value: appliedAmount, loanApplicationID });
    }

    dispatch({
      type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount,
      payload: {
        refinanceableDebts: newRefinanceableDebts,
        cashAmount: newCashValue,
        loanApplicationID,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const maxRefinanceableDebtsValues = useMemo(
    () =>
      refinanceableDebts.map((debt) =>
        maxLoanAmount ? Math.min(maxLoanAmount, debt.value) : debt.value
      ),
    [refinanceableDebts, maxLoanAmount]
  );

  /**
   * Deal with values changing outside the component
   */
  useEffect(() => {
    const isMaxAmountsInSync =
      maxRefinanceableDebtsValues.length ===
        maxRefinanceableDebtsValuesForSyncPurposes.current.length &&
      maxRefinanceableDebtsValues.every(
        (value, idx) =>
          value === maxRefinanceableDebtsValuesForSyncPurposes.current[idx]
      );

    if (isMaxAmountsInSync) return;

    maxRefinanceableDebtsValuesForSyncPurposes.current =
      maxRefinanceableDebtsValues;

    const { newRefinanceableDebts, newCashValue } = getDebtValuesOnPropChange({
      refinanceableDebts,
      maxLoanAmount,
      appliedAmount,
      isUpSell,
      currentRefinanceableDebts: _refinanceableDebts,
      currentCashAmount: _cashAmount,
      loanApplicationID,
    });

    dispatch({
      type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount,
      payload: {
        refinanceableDebts: newRefinanceableDebts,
        cashAmount: newCashValue,
        loanApplicationID,
      },
    });

    setIsInitialAllocationComplete(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refinanceableDebts]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnTotalAmountChange = useCallback(
    debounce(onTotalAmountChange, 300),
    [onTotalAmountChange]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnLoanDurationChange = useCallback(
    debounce(onLoanDurationChange, 300),
    [onLoanDurationChange]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnRefinanceAmountChange = useCallback(
    debounce(onRefinanceAmountChange, 300),
    [onRefinanceAmountChange]
  );

  // Cleanup debounced functions
  useEffect(() => {
    return () => {
      debouncedOnTotalAmountChange.cancel();
      debouncedOnLoanDurationChange.cancel();
      debouncedOnRefinanceAmountChange.cancel();
    };
  }, [
    debouncedOnTotalAmountChange,
    debouncedOnLoanDurationChange,
    debouncedOnRefinanceAmountChange,
  ]);

  const _totalLoanAmount = useMemo(
    () => _refinanceAmount + _cashAmount,
    [_refinanceAmount, _cashAmount]
  );

  useEffect(() => {
    if (appliedAmount === _totalLoanAmount) return;

    debouncedOnTotalAmountChange(_totalLoanAmount);
  }, [_totalLoanAmount, debouncedOnTotalAmountChange, appliedAmount]);

  /**
   * When refinanceAmount changes, we call the onRefinanceAmountChange callback debounced
   */
  useEffect(() => {
    debouncedOnRefinanceAmountChange(_refinanceAmount, _refinanceableDebts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_refinanceAmount, _refinanceableDebts]);

  const isOverMaxLoanAmount = useMemo(
    () =>
      maxLoanAmount ? _refinanceAmount + _cashAmount > maxLoanAmount : false,
    [_refinanceAmount, _cashAmount, maxLoanAmount]
  );

  useEffect(() => {
    if (
      isInitialAllocationComplete ||
      !allocationPriority ||
      !maxLoanAmount ||
      !isOverMaxLoanAmount
    ) {
      return;
    }

    const { cash: newCashValue, ...newRefinanceableDebts } = getLoanAllocation({
      maxLoanAmount,
      currentCashAmount: _cashAmount,
      priority: allocationPriority,
      currentRefinanceableDebts: _refinanceableDebts,
      isUpSell,
      isToggle: isRefinanceableDebtsToggleOnly,
    });

    const mergedRefinanceableDebts = _refinanceableDebts.map((debt) =>
      isRefinanceableDebtsToggleOnly
        ? {
            ...debt,
            isChecked: !!newRefinanceableDebts[debt.key],
          }
        : {
            ...debt,
            value: newRefinanceableDebts[debt.key],
          }
    );

    setIsInitialAllocationComplete(true);

    // Setting internal values
    dispatch({
      type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount,
      payload: {
        cashAmount: newCashValue,
        refinanceableDebts: mergedRefinanceableDebts,
        loanApplicationID,
      },
    });
  }, [
    _cashAmount,
    _refinanceableDebts,
    allocationPriority,
    isInitialAllocationComplete,
    isOverMaxLoanAmount,
    maxLoanAmount,
    loanApplicationID,
    isUpSell,
    isRefinanceableDebtsToggleOnly,
  ]);

  const isWarningBoxVisible = useMemo(
    () =>
      getWarningBoxVisibility({
        refinanceAmount: _refinanceAmount,
        loanDuration: loanDuration,
      }),
    [_refinanceAmount, loanDuration]
  );

  const monthlyPayment = useMemo(
    () =>
      calculateMonthlyPayment([
        _refinanceAmount + _cashAmount,
        _loanDuration,
        0.06,
      ]),
    [_refinanceAmount, _cashAmount, _loanDuration]
  );

  const _onCashAmountChange = useCallback(
    (newValue: number) => {
      const isOverLimit =
        maxLoanAmount && _refinanceAmount + newValue > maxLoanAmount;

      if (!isOverLimit) {
        setStoredAppliedAmount({ value: newValue, loanApplicationID });
        setInitialAppliedAmount(newValue);
        dispatch({
          type: TExtendedLoanOverviewActions.setCashAmount,
          payload: { value: newValue, loanApplicationID },
        });

        return;
      }

      const maxCashAmount = maxLoanAmount - _refinanceAmount;

      setStoredAppliedAmount({ value: maxCashAmount, loanApplicationID });
      setInitialAppliedAmount(maxCashAmount);
      dispatch({
        type: TExtendedLoanOverviewActions.setCashAmount,
        payload: { value: maxCashAmount, loanApplicationID },
      });
    },
    [_refinanceAmount, maxLoanAmount, loanApplicationID]
  );

  const _onLoanDurationChange = useCallback(
    (newValue: number) => {
      dispatch({
        type: TExtendedLoanOverviewActions.setLoanDuration,
        payload: { value: newValue },
      });

      onLoanDurationChange(newValue);
    },
    [onLoanDurationChange]
  );

  const onRefinanceableDebtCheckboxChange = useCallback(
    ({ debtType, newValue }: { debtType: string; newValue: boolean }) => {
      const newRefinanceableDebts = _refinanceableDebts.map((debt) =>
        debt.key === debtType ? { ...debt, isChecked: newValue } : debt
      );

      const newRefinanceAmount = getRefinanceAmount({
        refinanceableDebts: newRefinanceableDebts,
      });

      if (!maxLoanAmount)
        return dispatch({
          type: TExtendedLoanOverviewActions.setRefinanceableDebts,
          payload: {
            refinanceableDebts: newRefinanceableDebts,
            loanApplicationID,
          },
        });

      const maxCashAmount = Math.max(0, maxLoanAmount - newRefinanceAmount);

      // UpSelling
      if (isUpSell) {
        // if maxLoanAmount is not reached
        if (newRefinanceAmount + _cashAmount <= maxLoanAmount)
          return dispatch({
            type: TExtendedLoanOverviewActions.setRefinanceableDebts,
            payload: {
              refinanceableDebts: newRefinanceableDebts,
              loanApplicationID,
            },
          });
        // if maxLoanAmount is reached
        if (newRefinanceAmount + maxCashAmount <= maxLoanAmount) {
          return dispatch({
            type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount,
            payload: {
              refinanceableDebts: newRefinanceableDebts,
              cashAmount: maxCashAmount,
              loanApplicationID,
            },
          });
        }

        return;
      }

      // Not upSelling
      const newCashValue = Math.max(
        initialAppliedAmount - newRefinanceAmount,
        0
      );

      if (newRefinanceAmount + newCashValue > maxLoanAmount) {
        return;
      }

      return dispatch({
        type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount,
        payload: {
          refinanceableDebts: newRefinanceableDebts,
          cashAmount: newCashValue,
          loanApplicationID,
        },
      });
    },
    [
      _refinanceableDebts,
      dispatch,
      maxLoanAmount,
      _cashAmount,
      isUpSell,
      loanApplicationID,
      initialAppliedAmount,
    ]
  );

  const onDebtValueChange = useCallback(
    ({ newValue, debtType }: { newValue: number; debtType: string }) => {
      if (newValue < 0) return;

      const {
        cashAmount: newCashAmount,
        refinanceableDebts: newRefinanceableDebts,
      } = getValuesForRefinanceValueChange({
        newValue,
        debtType,
        refinanceableDebts: _refinanceableDebts,
        currentCashAmount: _cashAmount,
        maxLoanAmount,
        isUpSell,
        maxRefinanceableDebtsValues,
        initialAppliedAmount,
      });

      dispatch({
        type: TExtendedLoanOverviewActions.setDebtValuesAndCashAmount,
        payload: {
          cashAmount: newCashAmount,
          refinanceableDebts: newRefinanceableDebts,
          loanApplicationID,
        },
      });
    },
    [
      maxLoanAmount,
      _refinanceableDebts,
      maxRefinanceableDebtsValues,
      _cashAmount,
      isUpSell,
      loanApplicationID,
      initialAppliedAmount,
    ]
  );

  return (
    <ExtendedLoanOverviewContext.Provider
      value={useMemo(
        () => ({
          editMode,
          refinanceAmount: _refinanceAmount,
          maxRefinanceableDebtsValues,
          refinanceableDebts: _refinanceableDebts,
          isWarningBoxVisible,
          monthlyPayment,
          isOverMaxLoanAmount,
          cashAmount: _cashAmount,
          loanDuration: _loanDuration,
          maxLoanAmount,
          currency,
          totalLoanAmount: _totalLoanAmount,
          setEditMode,
          onDebtValueChange,
          onRefinanceableDebtCheckboxChange,
          onCashAmountChange: _onCashAmountChange,
          onLoanDurationChange: _onLoanDurationChange,
        }),
        [
          editMode,
          _refinanceableDebts,
          _refinanceAmount,
          isWarningBoxVisible,
          monthlyPayment,
          isOverMaxLoanAmount,
          maxLoanAmount,
          setEditMode,
          onDebtValueChange,
          onRefinanceableDebtCheckboxChange,
          _cashAmount,
          _loanDuration,
          _onLoanDurationChange,
          _onCashAmountChange,
          currency,
          _totalLoanAmount,
          maxRefinanceableDebtsValues,
        ]
      )}
    >
      {children}
    </ExtendedLoanOverviewContext.Provider>
  );
};

export const useExtendedLoanOverviewContext = () => {
  const context = useContext(ExtendedLoanOverviewContext);

  if (context === undefined) {
    throw new Error(
      'useExtendedLoanOverviewContext must be used within a ExtendedLoanOverviewContextProvider'
    );
  }

  return context;
};
