import { AxoToken } from '@axo/shared/auth/providers';
import { IDebtsResponse } from '../hooks/useAssentlyClient';
import { convertIntToDecimals } from './convertIntToDecimals';

export const convertDebtResponseIntToDecimals = (data: IDebtsResponse) => {
  if (data) {
    const convertedData = convertIntToDecimals(data);
    return convertedData;
  }

  return undefined;
};

type TGetDebts = {
  authToken: string | AxoToken;
  axoApiUrl: string;
  applicationId: string;
  customerJWT: string;
};

export const getDebts = async ({
  applicationId,
  authToken,
  axoApiUrl,
  customerJWT,
}: TGetDebts) => {
  const token = typeof authToken === 'string' ? authToken : authToken.JWT;

  const response = await fetch(
    `${axoApiUrl}/debt-register/${applicationId}/data`,
    {
      method: 'PATCH',
      headers: {
        jwt: customerJWT,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return convertDebtResponseIntToDecimals(await response.json());
};
