import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import {
  clearStoredAppliedAmount,
  clearStoredCashAmount,
  clearStoredRefinanceableDebts,
} from './clearStoredValues';
import { TStoredValues } from './storedValues.types';

type TGetStoredCashAmountParams = {
  loanApplicationID?: string;
};

export const getStoredCashAmount = ({
  loanApplicationID,
}: TGetStoredCashAmountParams) => {
  const savedCashAmountFromSessionStorage = JSON.parse(
    sessionStorage.getItem(TStoredValues.cashAmount) ?? '{}'
  );

  if (
    loanApplicationID &&
    savedCashAmountFromSessionStorage.loanApplicationID !== loanApplicationID
  ) {
    clearStoredCashAmount();
    return undefined;
  }

  const savedCashAmount = savedCashAmountFromSessionStorage.value
    ? parseInt(savedCashAmountFromSessionStorage.value, 10)
    : undefined;

  return savedCashAmount;
};
type TGetStoredRefinanceableDebtsParams = {
  refinanceableDebts?: TRefinanceableDebt[];
  loanApplicationID?: string;
};
export const getStoredRefinanceableDebts = ({
  refinanceableDebts,
  loanApplicationID,
}: TGetStoredRefinanceableDebtsParams) => {
  const savedRefinanceableDebtsFromSessionStorage = JSON.parse(
    sessionStorage.getItem(TStoredValues.refinanceableDebts) ?? '{}'
  );

  if (
    loanApplicationID &&
    savedRefinanceableDebtsFromSessionStorage.loanApplicationID !==
      loanApplicationID
  ) {
    clearStoredRefinanceableDebts();
    return undefined;
  }

  const savedRefinanceableDebts =
    savedRefinanceableDebtsFromSessionStorage?.value
      ? (JSON.parse(
          savedRefinanceableDebtsFromSessionStorage?.value
        ) as TRefinanceableDebt[])
      : undefined;

  if (!savedRefinanceableDebts) {
    return undefined;
  }

  if (!refinanceableDebts) {
    return savedRefinanceableDebts?.length
      ? savedRefinanceableDebts
      : undefined;
  }

  const allSavedDebtsAreValid = savedRefinanceableDebts?.every((savedDebt) =>
    refinanceableDebts.find(
      (debt) => debt.key === savedDebt.key && savedDebt.value <= debt.value
    )
  );

  if (!allSavedDebtsAreValid) {
    clearStoredRefinanceableDebts();
    return undefined;
  }

  return savedRefinanceableDebts?.length ? savedRefinanceableDebts : undefined;
};

export const getStoredAppliedAmount = ({
  loanApplicationID,
}: {
  loanApplicationID?: string;
}) => {
  const savedAppliedAmountFromSessionStorage = JSON.parse(
    sessionStorage.getItem(TStoredValues.appliedAmount) ?? '{}'
  );

  if (
    loanApplicationID &&
    savedAppliedAmountFromSessionStorage.loanApplicationID !== loanApplicationID
  ) {
    clearStoredAppliedAmount();
    return undefined;
  }

  const savedAppliedAmount = savedAppliedAmountFromSessionStorage?.value
    ? parseInt(savedAppliedAmountFromSessionStorage?.value, 10)
    : undefined;

  return savedAppliedAmount;
};

type TGetStoredValuesParams = TGetStoredRefinanceableDebtsParams;

export const getStoredValues = ({
  refinanceableDebts,
  loanApplicationID,
}: TGetStoredValuesParams) => ({
  savedCashAmount: getStoredCashAmount({ loanApplicationID }),
  getStoredRefinanceableDebts: getStoredRefinanceableDebts({
    refinanceableDebts,
    loanApplicationID,
  }),
  appliedAmount: getStoredAppliedAmount({
    loanApplicationID,
  }),
});
