import AxoFinansLogo from '@axo/ui-core/@assets/brand/axo-finans-logo.svg?react';
import AxoGroupLogo from '@axo/ui-core/@assets/brand/axo-group-logo.svg?react';
import AxoLainaLogo from '@axo/ui-core/@assets/brand/axo-laina-logo.svg?react';
import LendmeLogo from '@axo/ui-core/@assets/brand/lendme-logo.svg?react';
import { Text } from '@axo/ui-core/components/typography';
import { Country } from '@axo/ui-core/i18n/@types/Country.types.js';
import clsx from 'clsx';
import { FC } from 'react';
import styles from './poweredBy.module.scss';

const brands: Record<Country, FC> = {
  NO: AxoFinansLogo,
  FI: AxoLainaLogo,
  SE: AxoFinansLogo,
  DK: LendmeLogo,
  GB: AxoGroupLogo,
};

const texts: Record<Country, string> = {
  NO: 'Drevet av AxoFinans',
  FI: 'Axolainan voimalla',
  SE: 'Drivs av AxoFinans',
  DK: 'Drevet af LendMe',
  GB: 'Powered by',
};

type PoweredByProps = {
  market: Country;
  className?: string;
};

export const PoweredBy = ({ market, className }: PoweredByProps) => {
  const BrandLogo = brands[market];

  const text = texts[market];

  return (
    <footer className={clsx(styles.poweredBy, className)}>
      <Text className={styles.label}>
        <b>{text}</b>
      </Text>
      <BrandLogo />
    </footer>
  );
};
