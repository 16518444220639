import { TStoredValues } from './storedValues.types';

type TSetStoredValue = {
  value: string | number;
  loanApplicationID?: string;
};

export const setStoredCashAmount = ({
  value,
  loanApplicationID,
}: TSetStoredValue) => {
  const newJSON = JSON.stringify({ value, loanApplicationID });

  sessionStorage.setItem(TStoredValues.cashAmount, newJSON);
};

export const setStoredRefinanceableDebts = ({
  value,
  loanApplicationID,
}: TSetStoredValue) => {
  const newJSON = JSON.stringify({ value, loanApplicationID });

  sessionStorage.setItem(TStoredValues.refinanceableDebts, newJSON);
};

export const setStoredAppliedAmount = ({
  value,
  loanApplicationID,
}: TSetStoredValue) => {
  const newJSON = JSON.stringify({ value, loanApplicationID });

  sessionStorage.setItem(TStoredValues.appliedAmount, newJSON);
};
