type TGetWaringBoxVisibility = {
  refinanceAmount: number;
  loanDuration: number;
};

const getWarningBoxVisibility = ({
  refinanceAmount,
  loanDuration,
}: TGetWaringBoxVisibility) => {
  if (refinanceAmount < 1_000) return false;

  const thresholds = [
    { limit: 10_000, duration: 5 },
    { limit: 20_000, duration: 7 },
    { limit: 30_000, duration: 9 },
    { limit: 70_000, duration: 12 },
  ];

  const range = thresholds.find(
    (threshold) => refinanceAmount <= threshold.limit
  );

  return range ? loanDuration <= range.duration : false;
};

export default getWarningBoxVisibility;
