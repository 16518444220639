import { captureException } from '@sentry/browser';
import { useState } from 'react';
import { checkConsent } from '../utils/checkConsent';
import { submitConsent } from '../utils/submitConsent';

type useConsentProps = {
  applicationID: string;
  axoApiUrl: string;
};

export const useConsent = ({ applicationID, axoApiUrl }: useConsentProps) => {
  const [hasConsented, setHasConsented] = useState(false);

  const checkUserConsent = async (token: string) => {
    const consentData = await checkConsent({
      token,
      applicationId: applicationID,
      axoApiUrl,
    });

    const { hasConsent } = consentData;

    setHasConsented(hasConsent);
    return hasConsent;
  };

  const submitUserConsent = async (token: string) => {
    return submitConsent({
      token,
      applicationId: applicationID,
      axoApiUrl: axoApiUrl,
    })
      .then((data) => {
        setHasConsented(true);
        return data;
      })
      .catch((e: unknown) => {
        captureException(e);
        setHasConsented(false);
        return undefined;
      });
  };

  return { checkUserConsent, submitUserConsent, hasConsented };
};
