import { loan_application } from '@axo/shared/data-access/types';
import { IAPI } from '../../lib/models/api';

export const getAllCars =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (loanApplicationId: string): Promise<loan_application.Car[]> => {
    const url = new URL(`/loan-application/${loanApplicationId}/car`, baseUrl);

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error fetching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const postCar =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (
    loanApplicationId: string,
    Car: loan_application.CreateCar
  ): Promise<loan_application.Car> => {
    const url = new URL(`/loan-application/${loanApplicationId}/car`, baseUrl);

    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(Car),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error posting ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const patchCar =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (
    loanApplicationId: string,
    CarId: string,
    Car: loan_application.CreateCar
  ): Promise<loan_application.Car> => {
    const url = new URL(`/loan-application/${loanApplicationId}/car`, baseUrl);

    const response = await fetch(url, {
      method: 'PATCH',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ ...Car, ID: CarId }),
    });

    const responseJson = await response.json();

    if (response.ok) return responseJson;

    throw new Error(
      `Error patching ${response.url}: ${response.status} ${
        response.statusText
      } ${responseJson.error || responseJson.message}`
    );
  };

export const deleteCar =
  ({ url: { service: baseUrl }, token }: IAPI) =>
  async (loanApplicationID: string, CarID: string): Promise<void> => {
    const url = new URL(`/loan-application/${loanApplicationID}/car`, baseUrl);

    const response = await fetch(url, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ ID: CarID }),
    });

    if (response.ok) return;

    throw new Error(
      `Error deleting ${response.url}: ${response.status} ${response.statusText}`
    );
  };
