import { Icon } from '@axo/ui-core/components/Icon';
import { InputStateVariant } from '@axo/ui-core/components/input/InputProps.types';
import { Text } from '@axo/ui-core/components/typography';
import clsx from 'clsx';
import {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from 'react';
import { IInputProps } from '../../models/input.model';
import styles from './CheckboxUI.module.scss';

export const sizes = ['small', 'medium'] as const;

export interface ICheckbox
  extends IInputProps<HTMLInputElement, boolean | undefined> {
  className?: string;
  children?: ReactNode;
  label?: string;
  size?: (typeof sizes)[number];
  error?: string;
  state?: InputStateVariant;
  testid?: string;
  isUsingInternalState?: boolean;
}

export const CheckboxUI: ForwardRefRenderFunction<
  HTMLInputElement,
  ICheckbox
> = (
  {
    children = '',
    value,
    error,
    name,
    label,
    isUsingInternalState = true,
    onChange,
    onFocus,
    onBlur,
    onClick,
    className,
    testid,
    size = 'medium',
    state = 'neutral',
  },
  ref
) => {
  const inputId = useId();
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => setIsChecked(value), [value]);

  const _onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsChecked(e.target.checked);
      onChange?.(e);
    },
    [onChange]
  );

  const checkedValue = useMemo(
    () => (isUsingInternalState ? isChecked : value),
    [isChecked, value, isUsingInternalState]
  );

  return (
    <label
      className={clsx(styles.checkbox, className)}
      htmlFor={inputId}
      data-error={!!error}
      data-checked={checkedValue}
      data-size={size}
      data-state={state}
      data-testid={testid}
    >
      <div className={styles.row}>
        <div className={styles.checkmark}>
          <Icon name={'check'} className={styles.icon} />
        </div>
        <input
          ref={ref}
          id={inputId}
          name={name}
          type="checkbox"
          className={styles.checkbox}
          checked={checkedValue ?? false}
          onChange={_onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
        />
        <div className={styles.column}>
          {label && <Text className={styles.label}>{label}</Text>}
          {children && (
            <Text as={'span'} className={styles.description}>
              {children}
            </Text>
          )}

          {error && (
            <Text className={styles.errorText} size={'xs'}>
              {error}
            </Text>
          )}
        </div>
      </div>
    </label>
  );
};

export default forwardRef(CheckboxUI);
