import { useAnalytics } from '@axo/shared/services/analytics';
import { Events } from '@axo/shared/services/analytics/Events.taxonomy';
import { throttle } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useExtendedLoanOverviewContext } from './context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext';

type DebtRefinanceEvents = Extract<
  Events,
  'Debt Refinance Initiated' | 'Debt Refinance Updated'
>;

export const useDebtRefinanceAnalytics = () => {
  const { track } = useAnalytics();

  const { refinanceAmount, cashAmount, totalLoanAmount, refinanceableDebts } =
    useExtendedLoanOverviewContext();

  const isInitialized = useRef(false);

  const trackEvent = useCallback(
    (event: DebtRefinanceEvents) => {
      const totalDebt = refinanceableDebts.reduce(
        (sum, debt) => sum + debt.value,
        0
      );

      const numberOfDebtEntries = refinanceableDebts.length;

      track({
        event,
        params: {
          totalLoanAmount,
          totalDebt,
          refinanceAmount,
          cashAmount,
          numberOfDebtEntries,
        },
      });
    },
    [totalLoanAmount, refinanceableDebts, refinanceAmount, cashAmount, track]
  );

  const trackInitiated = useCallback(() => {
    trackEvent('Debt Refinance Initiated');
  }, [trackEvent]);

  const trackUpdated = throttle(
    () => {
      isInitialized.current
        ? trackEvent('Debt Refinance Updated')
        : (isInitialized.current = true);
    },
    5000,
    {
      trailing: true,
      leading: false,
    }
  );

  useEffect(() => {
    trackInitiated();

    return () => {
      trackUpdated.flush();
    };
  }, []);

  useEffect(() => {
    trackUpdated();

    return () => {
      trackUpdated.cancel();
    };
  }, [totalLoanAmount, refinanceableDebts, refinanceAmount, cashAmount]);
};
