/* eslint-disable max-classes-per-file */
import { Checkbox } from '@axo/deprecated/util/ui-components';
import React, { CSSProperties } from 'react';
import { ElementFactory, Question, Serializer } from 'survey-core';
import {
  ReactQuestionFactory,
  SurveyQuestionElementBase,
} from 'survey-react-ui';
import getBasicQuestionProps from '../../utils/getBasicQuestionProps';

class QuestionModel extends Question {
  questionType: string;

  constructor(questionType: string, name: string) {
    super(name);
    this.questionType = questionType;
    this.getType = this.getType.bind(this);
  }

  getType(): string {
    const type = this.questionType ?? '';
    return type;
  }

  public get checkedValue(): unknown {
    return this.getPropertyValue('checkedValue', true);
  }
  public set checkedValue(val: unknown) {
    this.setPropertyValue('checkedValue', val);
  }

  public get uncheckedValue(): unknown {
    return this.getPropertyValue('uncheckedValue', false);
  }
  public set uncheckedValue(val: unknown) {
    this.setPropertyValue('uncheckedValue', val);
  }
}

type QuestionProps = {
  creator?: any;
  isDisplaymode?: boolean;
  question?: QuestionModel;
};

export class SurveyQuestion extends SurveyQuestionElementBase {
  constructor(props: QuestionProps) {
    super(props);
    this.state = { errors: [] };
  }

  protected get question(): QuestionModel {
    return this.questionBase as QuestionModel;
  }

  get value() {
    return this.question.value;
  }

  // support readOnly and designMode
  get style() {
    return this.question.getPropertyValue('readOnly') ||
      this.question.isDesignMode
      ? ({
          pointerEvents: 'none',
        } as CSSProperties)
      : undefined;
  }

  protected renderElement(): JSX.Element {
    const { style, label, ...props } = getBasicQuestionProps(
      this,
      this.questionBase
    );

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        this.question.value = this.question.checkedValue;
      } else {
        this.question.value = this.question.uncheckedValue;
      }
    };

    return (
      <div style={style}>
        <Checkbox {...props} onChange={onChange}>
          <span
            dangerouslySetInnerHTML={{ __html: this.question.description }}
          />
        </Checkbox>
      </div>
    );
  }
}

export function registerCheckboxQuestion() {
  const questionType = 'Axo Checkbox';

  // this serializes the class into JSON
  Serializer.addClass(
    questionType,

    // Add properties that should be accessed in Creator.
    [
      {
        name: 'valueLabel',
        category: 'general',
        type: 'text',
        isLocalizable: true,
      },
    ],
    () => new QuestionModel(questionType, ''),
    'checkbox'
  );

  ReactQuestionFactory.Instance.registerQuestion(questionType, (props: any) =>
    React.createElement(SurveyQuestion, props)
  );

  ElementFactory.Instance.registerElement(
    questionType,
    (name: string) => new QuestionModel(questionType, name)
  );
}
