import { TRefinanceableDebt } from '../ExtendedLoanOverviewContext.types';
import { getStoredAppliedAmount } from '../store/getStoredValues';

type TGetDebtValuesOnPropChange = {
  maxLoanAmount?: number;
  appliedAmount: number;
  isUpSell: boolean;
  refinanceableDebts: TRefinanceableDebt[];
  currentRefinanceableDebts: TRefinanceableDebt[];
  currentCashAmount: number;
  loanApplicationID?: string;
};

export const getDebtValuesOnPropChange = ({
  maxLoanAmount,
  appliedAmount,
  isUpSell,
  refinanceableDebts,
  currentRefinanceableDebts,
  currentCashAmount,
  loanApplicationID,
}: TGetDebtValuesOnPropChange) => {
  const newRefinanceableDebts = refinanceableDebts.map((debt, idx) => ({
    ...debt,
    value: maxLoanAmount ? Math.min(debt.value, maxLoanAmount) : debt.value,
    isChecked: currentRefinanceableDebts?.[idx]
      ? currentRefinanceableDebts[idx].isChecked
      : true,
  }));

  const storedAppliedAmount = getStoredAppliedAmount({
    loanApplicationID,
  });

  const refinanceAmount = newRefinanceableDebts.reduce(
    (acc, { value, isChecked }) => (isChecked ? acc + value : acc),
    0
  );

  const maxCashValue = maxLoanAmount
    ? Math.max(maxLoanAmount - refinanceAmount, 0)
    : undefined;

  const newCashValue = isUpSell
    ? storedAppliedAmount ?? currentCashAmount ?? appliedAmount
    : storedAppliedAmount
    ? storedAppliedAmount - refinanceAmount
    : appliedAmount - refinanceAmount;

  return {
    newRefinanceableDebts,
    newCashValue: Math.min(newCashValue, maxCashValue ?? newCashValue),
  };
};
