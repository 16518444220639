import { FormFlagSet } from '@axo/form/configs';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { Icons } from '../../atoms/Icons';
import MessageBox from '../MessageBox';
import styles from './SaveAmount.module.scss';

type TSaveAmount = {
  title: string;
  description: string;
  icon?: 'info' | 'savings';
};

/**
 * @deprecated Avoid using ui-components. Try to split out functionality into
 * smaller libraries instead.
 */
export const SaveAmount = ({
  title,
  description,
  icon = 'info',
}: TSaveAmount) => {
  // TODO: Remove when experiment is done
  const { savingsExample } = useFlags(FormFlagSet);
  useFlagImpression(FormFlagSet, { savingsExample });

  const IconComponent = icon === 'info' ? Icons.CircleInfo : Icons.PiggyBank;

  if (savingsExample === false) return null;

  return (
    <MessageBox
      classes={{ root: styles.saveAmount }}
      iconVisible={false}
      variety="success"
    >
      <div className={styles.title}>
        <IconComponent size="lg" color={{ primary: '--success-900' }} />
        <strong>{title}</strong>
      </div>

      <div className={styles.description}>
        <span>{description}</span>
      </div>
    </MessageBox>
  );
};

export default SaveAmount;
