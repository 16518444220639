import { IDebt } from '@axo/form/feature/debt-registry';
import { ISurvey } from 'survey-core';
import { TRefinanceableDebt } from '../../../../../../components/ExtendedLoanOverview/context/ExtendedLoanOverviewContext/ExtendedLoanOverviewContext.types';
import { TRefinanceableDebt as TRefinanceableDebtQuestion } from '../ExtendedLoanOverview.question';

export type TDebtRegistryDebt = {
  balance: number;
  type: string;
  refinanceable: boolean;
  interestBearingBalance: number;
  nonInterestBearingBalance: number;
};

type TGetRefinanceableDebtsForExtendedLoanOverview = {
  refinanceableDebts?: TRefinanceableDebtQuestion[];
  isDebtRegistry: boolean;
  survey: ISurvey;
  debtRegistryDebts?: IDebt[];
  isEmptyDebtsVisible: boolean;
};

const getValueForRefinanceableDebtDetailed = ({
  debtRegistryDebts,
}: {
  debtRegistryDebts?: IDebt[];
}): TRefinanceableDebt[] => {
  if (!debtRegistryDebts) return [];

  const allDebts = debtRegistryDebts?.map(
    ({
      balance,
      type,
      refinanceable,
      financialInstitutionName,
      nonInterestBearingBalance,
      interestBearingBalance,
      nominalInterestRate,
      id,
    }) => {
      if (!refinanceable) return undefined;

      if (type === 'creditFacility') {
        const value = nonInterestBearingBalance + interestBearingBalance;

        if (!value) return undefined;

        return {
          value: nonInterestBearingBalance + interestBearingBalance,
          label: financialInstitutionName,
          key: id as string,
          step: 250,
          isChecked: true,
          interestRate: nominalInterestRate,
          type: 'cc',
        };
      }

      if (!balance) return undefined;

      return {
        value: balance,
        label: financialInstitutionName,
        key: id as string,
        step: 250,
        isChecked: true,
        interestRate: nominalInterestRate,
        type: 'unsecured',
      };
    }
  );

  const debtsFilteredAndSorted = (
    allDebts.filter((debt) => debt !== undefined) as TRefinanceableDebt[]
  ).sort((a, b) => (b?.interestRate ?? 0) - (a?.interestRate ?? 0));

  return debtsFilteredAndSorted;
};
/**
 * If for some reason Norway debts are meant to be shown as 3 groups instead of
 * explicitly showing them, look back into main branch, this file @22/10/2024
 * (was like this before the new changes)
 */
export const getRefinanceableDebts = ({
  refinanceableDebts,
  survey,
  isDebtRegistry,
  debtRegistryDebts,
  isEmptyDebtsVisible,
}: TGetRefinanceableDebtsForExtendedLoanOverview): TRefinanceableDebt[] => {
  if (isDebtRegistry && !debtRegistryDebts?.length) return [];

  const allDebts = isDebtRegistry
    ? getValueForRefinanceableDebtDetailed({ debtRegistryDebts })
    : refinanceableDebts?.map(({ valuePath, key, label, step }) => {
        const value = survey.getQuestionByName(valuePath)?.value ?? 0;

        return {
          value,
          label,
          key,
          step,
          isChecked: value > 0,
        };
      }) || [];

  return isEmptyDebtsVisible
    ? allDebts
    : allDebts?.filter(({ value }) => value > 0);
};
